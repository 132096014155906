/* globals location */
import React, { useLayoutEffect } from "react"
import styled from "styled-components"
import Lottie from "react-lottie"
import { AdyenCheckout, Dropin, AmazonPay } from '@adyen/adyen-web';


import * as api from "../../../api/Local"

import useQuery from "../../_shared/hooks/useQuery"

import * as loader from "./loading.json"


// ----------------------------------------------------------------------------

import '@adyen/adyen-web/styles/adyen.css';


const PM = {
  "paymentMethods": [
    {
      "configuration": {
        "merchantId": "A336WY66SG6SFM",
        "storeId": "amzn1.application-oa2-client.c8f0826dd206406e8f4836e2e7316b4c",
        "region": "EU",
        "publicKeyId": "LIVE-AFWKKGLGTF4KDXFSD6BRTB6X"
      },
      "name": "Amazon Pay",
      "type": "amazonpay"
    }
  ]
}


// ----------------------------------------------------------------------------

function PaypalPayment(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const query = useQuery()

  const step = query.get("step")
  const amazonCheckoutSessionId = query.get("amazonCheckoutSessionId")
  const amount = query.get("amount")



  // -------------------------------------
  // Effects
  // -------------------------------------

  useLayoutEffect(() => {
    initAmazonPaySession()

    if (window?.ReactNativeWebView?.postMessage) {
      console.log("ReactNativeWebview.postMessage is available")
      window?.ReactNativeWebview?.postMessage?.()
    } else {
      console.log("ReactNativeWebview.postMessage is NOT available")
    }

    //eslint-disable-next-line
  }, [])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function initAmazonPaySession() {

    const checkout = await AdyenCheckout({
      environment: "live",
      // clientKey: "live_EZR7IDPDWVBUXPL6R2YLBMQ73YLWUZ2T",
      clientKey: "live_ZJXTF67CURGYZEF2IR4XXEQKN43HWSVX",
      amount: {
        currency: "EUR",
        value: amount ?? 0
      },
      countryCode: "it-IT",
      // clientKey: "test_HDT45LBQWVBPPIWJCKBZOEFDTMF5BR7K",
      paymentMethodsResponse: PM,
      locale: "it-IT",
      // environment: "test",
      onSubmit: async (state, component, actions) => {
        try {
          console.log(">>> onSubmit: ", state)
        } catch (error) {
          console.error('## onSubmit - critical error', error);
          actions.reject();
        }
      },
    });



    if (!step) {
      const url = new URL(location?.href);
      url.searchParams.set('step', 'review');

      const amazonpay = new AmazonPay(checkout, {
        productType: 'PayOnly',
        returnUrl: url.toString(),
      }).mount('#amazonpay_button-container');

    }

    if (step === 'review') {

      const url = new URL(location?.href);
      url.searchParams.set('step', 'result');

      const amazonpay = new AmazonPay(checkout, {
        amazonCheckoutSessionId,
        cancelUrl: location?.href,
        returnUrl: url.toString(),
      }).mount('#amazonpay_button-container');
    }

    if (step === 'result') {
      const amazonpay = new AmazonPay(checkout, {

        amazonCheckoutSessionId,
        showOrderButton: false,
        onSubmit: (state, component) => {

          console.log("STATE: ", state)
          const bridgePayload = {
            ...state,
            status: "Authorised"

          }

          window?.ReactNativeWebView?.postMessage?.(JSON.stringify(bridgePayload))

        },
        onError: error => {
          if (error.resultCode) {
            const bridgePayload = {
              error
            }

            window?.ReactNativeWebView?.postMessage?.(JSON.stringify(bridgePayload))
          } else {
            console.error(error);
          }
        }
      }).mount('#amazonpay_button-container');

      amazonpay.submit();
    }

  }







  // async function capturePaypalPayment(orderId) {
  //   try {
  //     const order = await await api.getPayPalOrder(orderId)

  //     const bridgePayload = {
  //       ...order,
  //       status: "COMPLETED",
  //     }

  //     window?.ReactNativeWebView?.postMessage?.(JSON.stringify(bridgePayload))
  //   } catch (error) {}
  // }

  // async function createPaypalPayment() {
  //   if (orderAmount > 0) {
  //     const { url } = await api.createPayPalOrder(orderAmount)

  //     window.location = url
  //   }
  // }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const lottietOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }



  return (
    <div className={props.className}>
      {/* <Lottie options={lottietOptions} height={100} width={100} /> */}


      <div id="amazonpay_button-container"></div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------

const StyledPaypalPayment = styled(PaypalPayment)`
  & {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: "red";

    > div {
      width: 100vw;
      max-width: 500px;
    }

    .adyen-checkout__button.adyen-checkout__button--standalone.adyen-checkout__button--pay{
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledPaypalPayment
