import React, { useState, useEffect } from "react"
import PropTypes, { func } from "prop-types"
import styled from "styled-components"
import { message, Badge, Modal } from "antd"

import * as api from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import Table from "../../../_shared/components/Table"
import EditButton from "../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

import StyledPopconfirm from "../../../_shared/components/Popconfirm"

import StoreFormBottomSheet from "./StoreFormBottomSheet"

// ----------------------------------------------------------------------------

function Stores(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [{ token }] = useStateValue()

  const [
    stores,
    fetchStores,
    loading,
    pagination,
    totalRecordsNumber,
    onFiltersChange,
  ] = usePaginatedRestResource("/stores", token)
  const [
    volantini,
    fetchVolantini,
    loadingVol,
    paginationVol,
    totalRecordsNumberVol,
    onFiltersChangeVol,
  ] = usePaginatedRestResource("/newspapers", token)
  const [selectedStore, setSelectedStore] = useState(null)
  const [openSelectFile, setOpenSelectFile] = useState(null)
  const [importedStores, setImportedStores] = useState(null)
  const [search, setSearch] = useState("")

  // dayjs.locale("it")
  // var utc = require("dayjs/plugin/utc")
  // var timezone = require("dayjs/plugin/timezone")

  // dayjs.extend(utc)
  // dayjs.extend(timezone)
  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleCreateStore(post, place) {
    try {
      let body = {
        ...post,
        address: post?.address ?? place?.place,
        location: post?.location ?? place?.latAndLng,
        linkedVolantino: post?.linkedVolantino?.id
          ? post?.linkedVolantino?.id
          : post?.linkedVolantino,
      }
      await api.createResource("/stores", token, body)
      setSelectedStore(null)
      fetchStores()
      message.success("Store creato con successo")
    } catch (error) {
      console.error(error)
      message.error("Si è verificato un errore. Riprovare più tardi")
    }
  }

  async function handleUpdateStore(post, id, place) {
    let location = place?.latAndLng ?? post?.location
    try {
      let body = {
        ...post,
        location,
        linkedVolantino: post?.linkedVolantino?.id
          ? post?.linkedVolantino?.id
          : post?.linkedVolantino,
      }
      await api.updateResource(`/stores`, token, id, body)
      setSelectedStore(null)
      fetchStores()
      message.success("Store aggiornato con successo")
    } catch (error) {
      console.error(error)
      message.error("Si è verificato un errore. Riprovare più tardi")
    }
  }

  function handleStoreBottomSheetConfirm(post, place) {
    if (post.id) {
      return handleUpdateStore(post, post.id, place)
    } else {
      return handleCreateStore(post, place)
    }
  }

  async function startCreation() {
    // await createStructure(getAllPdfs());
    const response = await api.importFromCsv(importedStores, token)
    setOpenSelectFile()
    if (response) {
      message.success("Store importati con successo")
      setTimeout(() => {
        fetchStores()
        fetchVolantini()
      }, 2000)
    } else {
      message.error("Si è verificato un errore. Riprovare più tardi")
    }
  }

  async function handleDeleteStore(post) {
    try {
      if (!post?.id) {
        return
      }
      await api.deleteResource("/stores", token, post.id)
      fetchStores()
      message.success("Store eliminato con successo")
    } catch (error) {
      message.error("Si è verificato un errore. Riprovare più tardi")
    }
  }

  function handleStoreBottomSheetCancel() {
    setSelectedStore(null)
  }

  function renderActions(row) {
    return (
      <div className="actions">
        <EditButton onClick={() => setSelectedStore(row)} />

        <StyledPopconfirm
          onConfirm={() => handleDeleteStore(row)}
          placement="left"
          title="Sei sicuro di voler eliminare questo articolo?"
        >
          <DeleteButton />
        </StyledPopconfirm>
      </div>
    )
  }

  const changeHandler = (event) => {
    setImportedStores(event.target.files[0])
  }

  async function handleSearch(word) {
    setSearch(word)
    onFiltersChange({ search: word })
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const defaultStore = {
    published: false,

    disabled: false,

    services: {
      balance: "FALSO",
      boutique: "FALSO",
      clinic: "FALSO",
      club: "FALSO",
      laundry: "FALSO",
      aquarium: "FALSO",
      pharmacy: "FALSO",
      grooming: "FALSO",
    },
    openingHours: {
      monday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      tuesday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      wednesday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      thursday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      friday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      saturday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      sunday: {
        isClosed: true,
      },
    },
  }

  return (
    <div className={`${className}`}>
      <h1 className="page-title">Stores</h1>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <AppButton
          uxType="secondary"
          onClick={() => setSelectedStore(defaultStore)}
        >
          Nuovo Store
        </AppButton>
        <div style={{ marginLeft: 10 }}>
          <AppButton uxType="secondary" onClick={() => setOpenSelectFile(true)}>
            Importa file
          </AppButton>
        </div>
        <div style={{ marginLeft: 10 }}>
          <AppInput
            value={search}
            onChange={handleSearch}
            placeholder="Cerca"
            className="input"
          />
        </div>
      </div>

      <Table
        dataSource={stores}
        pagination={{ ...pagination, total: totalRecordsNumber }}
        onChange={(pagination) => fetchStores({ pagination })}
        columns={getColumns(renderActions)}
        loading={loading}
      />

      <StoreFormBottomSheet
        volantini={volantini}
        open={selectedStore ? true : false}
        post={selectedStore}
        onCancel={handleStoreBottomSheetCancel}
        onConfirm={handleStoreBottomSheetConfirm}
      />
      <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        open={openSelectFile}
        onOk={() => startCreation()}
        onCancel={() => setOpenSelectFile(false)}
      >
        <input
          onChange={changeHandler}
          type="file"
          name="file"
          accept=".csv"
          style={{ display: "block", margin: "10px auto" }}
        />
      </Modal>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Stores.propTypes = {
  className: PropTypes.string.isRequired,
}

Stores.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledStores = styled(Stores)`
  & {
    .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    .input {
      min-height: 36px !important;
      height: 36px;
      width: 250px;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledStores

function getColumns(renderActions) {
  return [
    {
      title: "",
      key: "disabled",
      dataIndex: "disabled",
      // eslint-disable-next-line
      render: (disabled) =>
        disabled ? <Badge status="error" /> : <Badge status="success" />,
    },
    {
      title: "Nome",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Codice",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Indirizzo",
      key: "address",
      dataIndex: "address",
      render: (address) => (
        <p>
          {address?.street}, {address?.city} ({address?.province})
        </p>
      ),
    },

    {
      key: "actions",
      render: renderActions,
    },
  ]
}
